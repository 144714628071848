import AppConfig from '@/configs/app.config.js'

import Vue from 'vue'
import Router from 'vue-router'

import Swal from 'sweetalert2'
import store from '@/store'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Views - Pages
const Page404 = () => import('@/views/pages/Page404')
const Page500 = () => import('@/views/pages/Page500')
const Login = () => import('@/views/pages/Login')

Vue.use(Router)

const router = new Router({
  mode: 'history', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/',
      name: 'Main',
      component: TheContainer,
      children: [
        {
          path: '',
          redirect: {
            name: 'Home'
          }
        },
        {
          path: 'home',
          name: 'Home',
          component: () => import('@/views/Home')
        },
        {
          path: 'profile',
          name: 'Profile',
          component: () => import('@/views/Profile')
        },
        {
          path: 'play',
          name: 'Play',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'PlayLotto',
              component: () => import('@/views/PlayLotto')
            },
            {
              path: ':roundId([0-9a-f]{24})',
              name: 'PlayRoundId',
              component: () => import('@/views/PlayLotto/PlayRoundId')
            }
          ]
        },
        {
          path: 'lottoset',
          component: { render (c) { return c('router-view') } },
          children: [
            {
              path: '',
              name: 'Lottosets',
              component: () => import('@/views/Lottosets')
            },
            {
              path: ':roundId([0-9a-f]{24})/:rateId([0-9a-f]{24})',
              name: 'PlayLottosetRoundId',
              component: () => import('@/views/lottoset/PlayLottosetRoundId')
            }
          ]
        },
        {
          path: 'ticket',
          name: 'Tickets',
          redirect: { name: 'WaitResultTickets' },
          component: () => import('@/views/Tickets'),
          children: [{
            path: 'wait',
            name: 'WaitResultTickets',
            component: () => import('@/views/ticket/WaitResultTickets')
          }, {
            path: 'round',
            name: 'RoundTickets',
            component: () => import('@/views/ticket/RoundTickets')
          }, {
            path: 'search',
            name: 'SearchTickets',
            component: () => import('@/views/ticket/SearchTickets')
          }]
        },
        {
          path: 'report',
          name: 'Report',
          redirect: { name: 'Transactions' },
          component: () => import('@/views/Report'),
          children: [
            {
              path: 'balance',
              name: 'Transactions',
              component: () => import('@/views/report/Transactions')
            },
            {
              path: 'wait',
              name: 'WaitTickets',
              component: () => import('@/views/report/WaitTickets')
            },
            {
              path: 'summary',
              name: 'Summary',
              component: () => import('@/views/report/Summary')
            }
          ]
        },
        {
          path: 'result',
          name: 'Results',
          component: () => import('@/views/Results')
        },
        {
          path: 'rule',
          name: 'Rule',
          component: () => import('@/views/Rule')
        },
        {
          path: 'howto',
          name: 'Howto',
          component: () => import('@/views/Howto')
        },
        {
          path: 'help',
          name: 'Help',
          component: () => import('@/views/Help')
        },
        {
          path: 'links',
          name: 'Links',
          component: () => import('@/views/Links')
        },
        {
          path: 'game',
          name: 'Game',
          redirect: {
            name: 'GameCats'
          },
          component: () => import('@/views/Game'),
          children: [{
            path: 'list',
            name: 'GameCats',
            component: () => import('@/views/game/GameCats')
          }, {
            path: 'winlose',
            name: 'GameWinLose',
            component: () => import('@/views/game/GameWinLose')
          }, {
            path: 'credit',
            name: 'GameCredit',
            component: () => import('@/views/game/GameCredit')
          }, {
            path: 'history',
            name: 'GameHistory',
            component: () => import('@/views/game/GameHistory')
          }]
        }
      ]
    },
    {
      path: '/play-game/:gameId([0-9a-f]{24})',
      name: 'PlayGame',
      component: () => import('@/views/game/PlayGame')
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/404',
      name: 'Page404',
      component: Page404
    },
    {
      path: '/500',
      name: 'Page500',
      component: Page500
    }
  ]
}

router.beforeEach((to, from, next) => {
  const publicPages = ['Login'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem(AppConfig.key.LoggedIn);

  if(store.state.isProcessTicket) {
    Swal.fire({
      text: 'กรุณาอย่าเปลี่ยนหน้าขณะกำลังส่งโพย',
      icon: 'warning',
      confirmButtonText: 'OK'
    })
  }else
  if (authRequired && !loggedIn) {
    next({name: 'Login'});
  } else {
    next();
  }
})

export default router
